export const assessmentsApiHandler = {
  getUserAnswers: {
    method: "GET",
    path: (payload: { assessmentId: string | number }) => `assessments/${payload.assessmentId}/assessment-answers`
  },
  getSingleAssessmentOptions: {
    method: "GET",
    path: (payload: { assessmentId: string | number }) => `assessment-options/${payload.assessmentId}`
  },
  updateUserAnswers: {
    method: "PUT",
    path: (payload: { assessmentId: string | number }) => `assessments/${payload.assessmentId}/answers`
  }
};