import { adminEnumRoutes } from '@/routes/admin/admin.enum.routes';

export const AdminMenuItems = [
  adminEnumRoutes.dashboard,
  adminEnumRoutes.users,
  adminEnumRoutes.teams,
  adminEnumRoutes.journeys,
  adminEnumRoutes.courses,
  adminEnumRoutes.LearningCategories,
  adminEnumRoutes.registrationRequest,
  adminEnumRoutes.approvals,
  adminEnumRoutes.grades,
  adminEnumRoutes.socialPlatform,
  adminEnumRoutes.events,
  adminEnumRoutes.transactions,
  adminEnumRoutes.refunds,
  adminEnumRoutes.announcements,
  adminEnumRoutes.newsLetters,
  adminEnumRoutes.articles,
  adminEnumRoutes.issuedCertificates,
  adminEnumRoutes.permissions,
  adminEnumRoutes.organizationOverview,
];
