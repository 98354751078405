export const courseReviewsApiHandler = {
  getAllCourseReviews: {
    method: "GET",
    path: (payload: { courseId: string | number }) => `courses/${payload.courseId}/reviews`,
    params: {} as {'queryParams'?: string | number}
  },
  saveCourseReviewModeration: {
    method: "POST",
    path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/moderate-course-review`
  },
  replyToCourseReview: {
    method: "POST",
    path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/moderate-course-review/reply`
  },
  deleteCourseReviewAdminReply: {
    method: "DELETE",
    path: (payload: { courseId: string | number , courseReviewAdminReplyId: string | number }) => `org-courses-management/${payload.courseId}/moderate-course-review/reply/${payload.courseReviewAdminReplyId}`
  }
};