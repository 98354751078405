export const organizationsApiHandler = {
  getOrganization: {
    method: "GET",
    path: () => `organizations-dashboard`
  },
  getIdFromPrefix: {
    method: "GET",
    path: (payload: { prefix: string | number }) => `organizations-dashboard/prefix/${payload.prefix}`
  },
  getOrganizationsRegistrationFormFields: {
    method: "GET",
    path: () => `organizations-dashboard/registration`
  },
  getOrganizationsRegistrationFormFieldsSort: {
    method: "GET",
    path: () => `organizations-dashboard/registration`
  },
  getOrganizationsTerminology: {
    method: "GET",
    path: () => `organizations-dashboard/terminology`
  },
  getOrganizationsTerminologyDashboard: {
    method: "GET",
    path: () => `organizations-dashboard/terminology/dashboard`
  },
  getRegistrationFormFieldsSort: {
    method: "GET",
    path: () => `organizations-dashboard/registration/sort`
  },
  getOrganizationAssessments: {
    method: "GET",
    path: () => `org-assessments-management`
  },
  getLearningCategories: {
    method: "GET",
    path: () => `learning-categories`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  getParentLearningCategories: {
    method: "GET",
    path: () => `learning-categories/parent`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  }
};