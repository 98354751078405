export const usersApiHandler = {
  courses: {
    getOrgUserCourses: {
      method: "GET",
      path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}/courses`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    }
  },
  checklists: {
    getOrgUserChecklists: {
      method: "GET",
      path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}/checklists`
    },
    markChecklistItemAsComplete: {
      method: "POST",
      path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}/checklists/mark-as-complete`
    },
    deleteChecklistItem: {
      method: "DELETE",
      path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}/checklists/mark-as-complete`
    }
  },
  approvalRequests: {
    getUserApprovalRequests: {
      method: "GET",
      path: () => `org-users-management/approval-requests`,
      params: {} as {'status'?: string | number, 'skip'?: string | number, 'take'?: string | number}
    },
    acceptUserApprovalRequest: {
      method: "POST",
      path: (payload: { userId: string | number }) => `org-users-management/approval-requests/${payload.userId}/approve`
    },
    rejectUserApprovalRequest: {
      method: "POST",
      path: (payload: { userId: string | number }) => `org-users-management/approval-requests/${payload.userId}/reject`
    }
  },
  getAllOrgUsers: {
    method: "GET",
    path: () => `org-users-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'permissionSetId'?: string | number, 'requiresApproval'?: string | number, 'isVerified'?: string | number, 'fromDate'?: string | number, 'toDate'?: string | number}
  },
  createOrgUser: {
    method: "POST",
    path: () => `org-users-management`
  },
  getSingleOrgUser: {
    method: "GET",
    path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}`
  },
  updateOrgUser: {
    method: "PATCH",
    path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}`
  },
  resetUserPassword: {
    method: "POST",
    path: (payload: { userId: string | number }) => `org-users-management/${payload.userId}/reset-password`
  },
  addBulkUsersToOrg: {
    method: "POST",
    path: () => `org-users-management/add-bulk`
  }
};