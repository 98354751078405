import {chartsApiHandler} from './charts';
import {permissionSetsApiHandler} from './permissionSets';
import {providersApiHandler} from './providers';
import {checklistsApiHandler} from './checklists';
import {learningGroupsApiHandler} from './learningGroups';
import {socialGroupsApiHandler} from './socialGroups';
import {socialEventsApiHandler} from './socialEvents';
import {usersApiHandler} from './users';
import {coursesApiHandler} from './courses';
import {learningCategoriesApiHandler} from './learningCategories';
import {specializationApiHandler} from './specialization';
import {gradingSchemasApiHandler} from './gradingSchemas';
import {ordersApiHandler} from './orders';
import {supportApiHandler} from './support';
import {approvalsApiHandler} from './approvals';
import {organizationsApiHandler} from './organizations';
import {certificateTamplateApiHandler} from './certificateTamplate';
import {terminologyApiHandler} from './terminology';
import {creditUnitsApiHandler} from './creditUnits';
import {passingCriteriaApiHandler} from './passingCriteria';
import {subscriptionManagementApiHandler} from './subscriptionManagement';
import {issuedCertificatesApiHandler} from './issuedCertificates';
import {announcementsApiHandler} from './announcements';
import {registrationFormApiHandler} from './registrationForm';
import {articlesApiHandler} from './articles';
import {tagsApiHandler} from './tags';
import {galleryApiHandler} from './gallery';
import {courseReviewsApiHandler} from './courseReviews';
import {conversionRatesApiHandler} from './conversionRates';
import {getOrgUserCoursesApiHandler} from './getOrgUserCourses';
import {getOrganizationApiHandler} from './getOrganization';
import {updateOrganizationApiHandler} from './updateOrganization';
import {subscribeToSSEApiHandler} from './subscribeToSSE';
import {getOrganizationNewsletterSubscribersApiHandler} from './getOrganizationNewsletterSubscribers';

export const ApiHandler = {
    charts: chartsApiHandler,
    permissionSets: permissionSetsApiHandler,
    providers: providersApiHandler,
    checklists: checklistsApiHandler,
    learningGroups: learningGroupsApiHandler,
    socialGroups: socialGroupsApiHandler,
    socialEvents: socialEventsApiHandler,
    users: usersApiHandler,
    courses: coursesApiHandler,
    learningCategories: learningCategoriesApiHandler,
    specialization: specializationApiHandler,
    gradingSchemas: gradingSchemasApiHandler,
    orders: ordersApiHandler,
    support: supportApiHandler,
    approvals: approvalsApiHandler,
    organizations: organizationsApiHandler,
    certificateTamplate: certificateTamplateApiHandler,
    terminology: terminologyApiHandler,
    creditUnits: creditUnitsApiHandler,
    passingCriteria: passingCriteriaApiHandler,
    subscriptionManagement: subscriptionManagementApiHandler,
    issuedCertificates: issuedCertificatesApiHandler,
    announcements: announcementsApiHandler,
    registrationForm: registrationFormApiHandler,
    articles: articlesApiHandler,
    tags: tagsApiHandler,
    gallery: galleryApiHandler,
    courseReviews: courseReviewsApiHandler,
    conversionRates: conversionRatesApiHandler,
    getOrgUserCourses: getOrgUserCoursesApiHandler,
    getOrganization: getOrganizationApiHandler,
    updateOrganization: updateOrganizationApiHandler,
    subscribeToSSE: subscribeToSSEApiHandler,
    getOrganizationNewsletterSubscribers: getOrganizationNewsletterSubscribersApiHandler,
}