import {authApiHandler} from './auth';
import {contactApiHandler} from './contact';
import {coachingApiHandler} from './coaching';
import {examSystemApiHandler} from './examSystem';
import {integrationsApiHandler} from './integrations';
import {lMSApiHandler} from './lMS';
import {localeApiHandler} from './locale';
import {videoConferencingToolsApiHandler} from './videoConferencingTools';
import {miscApiHandler} from './misc';
import {socialApiHandler} from './social';
import {scheduleApiHandler} from './schedule';
import {dashboardApiHandler} from './dashboard';
import {organizationsApiHandler} from './organizations';
import {saaSApiHandler} from './saaS';
import {articlesApiHandler} from './articles';
import {externalIntegrationsApiHandler} from './externalIntegrations';
import {galleryApiHandler} from './gallery';
import {assessmentsApiHandler} from './assessments';
import {clearCacheApiHandler} from './clearCache';
import {testingApiHandler} from './testing';
import {getUserHomeInfoApiHandler} from './getUserHomeInfo';

export const ApiHandler = {
    auth: authApiHandler,
    contact: contactApiHandler,
    coaching: coachingApiHandler,
    examSystem: examSystemApiHandler,
    integrations: integrationsApiHandler,
    lMS: lMSApiHandler,
    locale: localeApiHandler,
    videoConferencingTools: videoConferencingToolsApiHandler,
    misc: miscApiHandler,
    social: socialApiHandler,
    schedule: scheduleApiHandler,
    dashboard: dashboardApiHandler,
    organizations: organizationsApiHandler,
    saaS: saaSApiHandler,
    articles: articlesApiHandler,
    externalIntegrations: externalIntegrationsApiHandler,
    gallery: galleryApiHandler,
    assessments: assessmentsApiHandler,
    clearCache: clearCacheApiHandler,
    testing: testingApiHandler,
    getUserHomeInfo: getUserHomeInfoApiHandler,
}