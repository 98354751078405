import { MultiParams } from '../../../helpers/interfaces/admin/multiParams.interface';
export const ordersApiHandler = {
  getOrderById: {
    method: "GET",
    path: (payload: { orderId: string | number }) => `org-orders-management/${payload.orderId}`
  },
  getAllOrgRefunds: {
    method: "GET",
    path: () => `org-orders-management/refunds`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'sort'?: string | number, 'minPrice'?: string | number, 'maxPrice'?: string | number, 'statuses'?: (string | number)[], 'types'?: (string | number)[], 'search'?: string | number, 'startDate'?: string | number, 'endDate'?: string | number}
  },
  sendOrgRefundDecision: {
    method: "POST",
    path: () => `org-orders-management/refund-decision`
  },
  getAllOrgOrders: {
    method: "GET",
    path: () => `org-orders-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'sort'?: string | number, 'endDate'?: string | number, 'startDate'?: string | number, 'search'?: string | number, 'minPrice'?: string | number, 'maxPrice'?: string | number, 'statuses'?: (string | number)[], 'types'?: (string | number)[]}
  },
  getAllOrgPurchasedItems: {
    method: "GET",
    path: () => `org-orders-management/purchased-items`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'sort'?: string | number, 'endDate'?: string | number, 'startDate'?: string | number, 'search'?: string | number, 'minPrice'?: string | number, 'maxPrice'?: string | number, 'statuses'?: (string | number)[], 'types'?: (string | number)[]}
  },
  getAllOrgFailedPayments: {
    method: "GET",
    path: () => `org-orders-management/failed-payments`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'sort'?: string | number, 'endDate'?: string | number, 'startDate'?: string | number, 'search'?: string | number, 'minPrice'?: string | number, 'maxPrice'?: string | number, 'statuses'?: (string | number)[], orderBy: MultiParams}
  },
  getAllOrgOrdersFilterOptions: {
    method: "GET",
    path: () => `org-orders-management/filter-options`
  }
};