export const organizationsApiHandler = {
  registrationForm: {
    sorting: {
      sortRegistrationFormFields: {
        method: "PATCH",
        path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration/sort`
      },
      getRegistrationFormFieldsSort: {
        method: "GET",
        path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration/sort`
      }
    },
    addRegistrationFormField: {
      method: "POST",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration`
    },
    getRegistrationFormFields: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration`
    },
    deleteRegistrationFormField: {
      method: "DELETE",
      path: (payload: { organizationId: string | number , formFieldId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration/${payload.formFieldId}`
    },
    updateRegistrationFormField: {
      method: "PATCH",
      path: (payload: { organizationId: string | number , formFieldId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration/${payload.formFieldId}`
    },
    reorderRegistrationFormField: {
      method: "PATCH",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/registration/reorder`
    }
  },
  configuration: {
    getOrganizationConfiguration: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/configuration`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    updateOrganizationConfiguration: {
      method: "PATCH",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/configuration`
    }
  },
  colors: {
    getOrganizationColors: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/colors`
    },
    updateOrganizationColors: {
      method: "PATCH",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/colors`
    }
  },
  customConfigs: {
    getAllCustomConfigsForOrg: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/custom-configs/all`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    getSingleCustomConfigs: {
      method: "GET",
      path: (payload: { organizationId: string | number , key: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/custom-configs/${payload.key}`
    },
    createOrUpdateCustomConfigs: {
      method: "POST",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/custom-configs`
    },
    deleteSingleCustomConfigs: {
      method: "DELETE",
      path: (payload: { organizationId: string | number , key: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/custom-configs/${payload.key}`
    }
  },
  fontFamilies: {
    getOrganizationsFontFamilies: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/font-families`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    updateOrganizationFontFamilies: {
      method: "PATCH",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/font-families`
    }
  },
  terminology: {
    getOrganizationsTerminology: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology`
    },
    getOrganizationsTermById: {
      method: "GET",
      path: (payload: { organizationId: string | number , terminologyId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology/${payload.terminologyId}`
    },
    getOrganizationsTerminologyDashboard: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology/dashboard`,
      params: {} as {'type'?: string | number, 'termKeyCategoryId'?: string | number, 'skip'?: string | number, 'take'?: string | number}
    },
    createOrganizationsTerminology: {
      method: "POST",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology`
    },
    updateOrganizationsTerminology: {
      method: "PATCH",
      path: (payload: { organizationId: string | number , terminologyId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology/${payload.terminologyId}`
    },
    deleteOrganizationsTerminology: {
      method: "DELETE",
      path: (payload: { organizationId: string | number , terminologyId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/terminology/${payload.terminologyId}`
    }
  },
  creditUnits: {
    getOrganizationCreditUnits: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/credit-units`
    }
  },
  passingCriteria: {
    getOrganizationPassingCriteria: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/passing-criteria`
    },
    updateOrganizationPassingCriteria: {
      method: "PUT",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/passing-criteria`
    }
  },
  certificateTemplates: {
    getOrganizationCertificateTemplate: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/certificate-template`
    },
    updateOrganizationCertificateTemplate: {
      method: "PUT",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/certificate-template`
    }
  },
  pricingModel: {
    getPricingModel: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/pricing-model`
    },
    putPricingModel: {
      method: "PUT",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}/pricing-model`
    }
  },
  learningCategories: {
    getOrganizationLearningCategories: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `learning-categories/organization/${payload.organizationId}`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
    }
  },
  updateOrganization: {
    method: "PATCH",
    path: (payload: { organizationId: string | number }) => `admin-dashboard/organizations/${payload.organizationId}`
  },
  createOrganization: {
    method: "POST",
    path: () => `organizations`
  }
};