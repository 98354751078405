import { z } from 'zod';

import { EnumOrganizationMemberPermissions } from '@/helpers/enums/permission.enum';

const PathFuncSchema = z.record(z.coerce.number());

type PathFuncParam = z.infer<typeof PathFuncSchema>;

export const adminEnumRoutes = {
  root: {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: 'sidemenu.dashboard',
      requiresAuth: true,
      isAdministrator: true,
    },
  },
  dashboard: {
    path: '/admin/dashboard',
    name: 'Dashboard',
    meta: {
      title: 'sidemenu.dashboard',
      icon: 'mdi-view-dashboard-outline',
      requiresAuth: true,
      isAdministrator: true,
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadMemberActivity,
        EnumOrganizationMemberPermissions.ReadMembers,
        EnumOrganizationMemberPermissions.ReadSales,
      ],
    },
  },
  /** >>>>>>>>>>>>>>> User Routes <<<<<<<<<<<<<<< **/
  users: {
    path: '/admin/users',
    name: 'Users View',
    meta: {
      title: 'sidemenu.users',
      icon: 'mdi-account',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMembers],
    },
  },
  createUser: {
    path: '/admin/users/create',
    name: 'Create User',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ModifyMembers],
    },
  },
  userView: {
    path: '/admin/users',
    name: 'User View',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMembers],
    },
  },
  userDetails: {
    path: '/admin/users/:userId/details',
    name: 'User Details',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMembers],
    },
  },
  userCourses: {
    path: '/admin/users/:userId/courses',
    name: 'User Courses',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberActivity],
    },
  },
  courseReviews: {
    path: '/admin/courses/mine/:courseId/reviews',
    name: 'Course Reviews',
    pathFn: ({ param: courseId }: PathFuncParam) =>
      `/admin/courses/mine/:courseId/${courseId}/reviews`,
    meta: {
      title: 'sidemenu.reviews',
    },
  },
  userExternalCourses: {
    path: '/admin/users/:userId/externalCourses',
    name: 'User External Courses',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberActivity],
    },
  },
  userChecklist: {
    path: '/admin/users/:userId/checklist',
    name: 'User Checklist',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadLearningPaths],
    },
  },
  /** >>>>>>>>>>>>>>> Journey Routes <<<<<<<<<<<<<<< **/
  journeys: {
    path: '/admin/journey-planner',
    name: 'Journey View',
    meta: {
      title: 'sidemenu.journeyPlanner',
      icon: 'mdi-order-bool-ascending-variant',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadLearningPaths,
        EnumOrganizationMemberPermissions.OrganizationLearningPath,
      ],
    },
  },
  journeysOverview: {
    path: '/admin/journey-planner',
    name: 'Journey Overview',
    meta: {
      title: 'journeyPlanner.journeyPlannerOverview',
      icon: 'mdi-order-bool-ascending-variant',
    },
  },
  createJourneys: {
    path: '/admin/journey-planner/create',
    name: 'Create a Journey Planner',
    meta: {
      title: 'journeyPlanner.createJourneyTab',
    },
  },
  editJourney: {
    path: '/admin/journey-planner/:journeyId/edit',
    name: 'Journey create',
    meta: {
      title: 'journeyPlanner.createJourneyTab',
    },
  },
  journeyDetails: {
    path: '/admin/journey-planner/:journeyId/details',
    pathFn: ({ journeyId }: { journeyId: string | number }) =>
      `/admin/journey-planner/${journeyId}/details`,
    name: 'Journey Details',
    meta: {
      title: 'journeyPlanner.journeyPlannerDetails',
    },
  },
  journeyAssign: {
    path: '/admin/journey-planner/:journeyId/assign',
    pathFn: ({ journeyId }: { journeyId: string | number }) =>
      `/admin/journey-planner/${journeyId}/assign`,
    name: 'Journey Assign',
    meta: {
      title: 'journeyPlanner.journeyPlannerAssign',
    },
  },
  journeyItems: {
    path: '/admin/journey-planner/:journeyId/items',
    pathFn: ({ journeyId }: { journeyId: string | number }) =>
      `/admin/journey-planner/${journeyId}/details`,
    name: 'journeyPlanner Items',
    meta: {
      title: 'journeyPlanner.journeyPlannerItems',
    },
  },
  journeyItemsOverView: {
    path: '/admin/journey-planner/:journeyId/items/:itemId',
    name: 'Journey Item View',
    meta: {
      title: 'journeyPlanner.journeyPlannerItemView',
    },
  },
  journeyItemsEdit: {
    path: '/admin/journey-planner/:journeyId/items/:itemId/edit',
    name: 'Journey Item Edit',
    meta: {
      title: 'journeyPlanner.journeyPlannerEdit',
    },
  },
  createJourneysItem: {
    path: '/admin/journey-planner/:journeyId/items/create',
    name: 'New Journey Item',
    meta: {
      title: 'journeyPlanner.createJourneyTab',
    },
  },
  /** >>>>>>>>>>>>>>> Teams Routes <<<<<<<<<<<<<<< **/
  teams: {
    path: '/admin/teams',
    name: 'Teams View',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadLearningPaths],
    },
  },
  teamsOverview: {
    path: '/admin/teams/:teamId',
    pathFn: ({ teamId }: PathFuncParam) => `/admin/teams/${teamId}`,

    name: 'Team Overview',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
    },
  },
  teamMembers: {
    path: '/admin/teams/:teamId/members',
    pathFn: ({ teamId }: PathFuncParam) => `/admin/teams/${teamId}/members`,
    name: 'Team Members',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
    },
  },
  teamCourses: {
    path: '/admin/teams/:teamId/courses',
    pathFn: ({ teamId }: PathFuncParam) => `/admin/teams/${teamId}/courses`,
    name: 'Team Courses',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
    },
  },
  createTeam: {
    path: '/admin/teams/create',
    name: 'Create Team',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
    },
  },
  editTeam: {
    path: '/admin/teams/:teamId/edit',
    name: 'Edit Team',
    meta: {
      title: 'sidemenu.teams',
      icon: 'mdi-account-group',
    },
  },
  /** >>>>>>>>>>>>>>> Courses Routes <<<<<<<<<<<<<<< **/
  courses: {
    path: '/admin/courses',
    name: 'Courses View',
    meta: {
      title: 'sidemenu.courses',
      icon: 'mdi-bookshelf',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadCourses,
        EnumOrganizationMemberPermissions.ReadSpecializations,
      ],
    },
  },
  myCourses: {
    path: '/admin/courses/mine',
    name: 'My Courses View',
    meta: {
      title: 'sidemenu.myCourses',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  importedCourses: {
    path: '/admin/courses/imported',
    name: 'Imported Courses View',
    meta: {
      title: 'sidemenu.importedCourses',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadImportedCourses,
      ],
    },
  },
  externalCourses: {
    path: '/admin/courses/external',
    name: 'External Courses View',
    meta: {
      title: 'sidemenu.externalCourses',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadExternalCourses,
      ],
    },
  },

  externalCoursesOverview: {
    path: '/admin/courses/external/:externalCourseId',
    pathFn: ({ externalCourseId }: PathFuncParam) =>
      `/admin/courses/external/${externalCourseId}`,
    name: 'External Course Overview',
    meta: {
      title: 'sidemenu.externalCourses',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadExternalCourses,
      ],
    },
  },
  externalCoursesDetails: {
    path: ' ',

    name: 'External Course Details',
    meta: {
      title: 'sidemenu.externalCoursesDetails',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadExternalCourses,
      ],
    },
  },
  externalCoursesStudents: {
    path: '/admin/courses/external/:externalCourseId/students',
    pathFn: ({ externalCourseId }: PathFuncParam) =>
      `/admin/courses/external/${externalCourseId}/students`,
    name: 'External Course students',
    meta: {
      title: 'sidemenu.externalCoursesStudents',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadExternalCourses,
      ],
    },
  },
  specialization: {
    path: '/admin/courses/course-bundle',
    name: 'Course Bundle View',
    meta: {
      title: 'sidemenu.specialization',
    },
  },
  audienceControl: {
    path: '/admin/courses/mine/:courseId/audience-control',
    name: 'Audience Control',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/:courseId/${courseId}/audience-control`,
    meta: {
      title: 'sidemenu.audienceControl',
    },
  },
  courseCertificate: {
    path: '/admin/courses/mine/:courseId/certificate',
    name: 'Course Certificate',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/:courseId/${courseId}/certificate`,
    meta: {
      title: 'sidemenu.certificate',
    },
  },
  importedCourseAudienceControl: {
    path: '/admin/courses/imported/:courseId/audience-control',
    name: ' Imported Course Audience Control',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/imported/${courseId}/audience-control`,

    meta: {
      title: 'sidemenu.audienceControl',
    },
  },
  adminCourseOverview: {
    path: '/admin/courses/mine/:courseId',
    pathFn: ({ courseId }: PathFuncParam) => `/admin/courses/mine/${courseId}`,
    name: 'Admin Course Overview',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  adminCourseDetails: {
    path: '/admin/courses/mine/:courseId/details',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/${courseId}/details`,
    name: 'Admin Course Details',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  adminCourseInstructors: {
    path: '/admin/courses/mine/:courseId/instructors',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/${courseId}/instructors`,
    name: 'Admin Course Instructors',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMembers],
    },
  },
  adminCourseStudents: {
    path: '/admin/courses/mine/:courseId/students',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/${courseId}/ `,
    name: 'Admin Course students',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  adminCourseInterestedStudents: {
    path: '/admin/courses/mine/:courseId/interested',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/mine/${courseId}/interested `,
    name: 'Admin Course Interested Students',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  importedCourseOverview: {
    path: '/admin/courses/imported/:courseId',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/imported/${courseId}`,
    name: 'Imported Course Overview',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadImportedCourses,
      ],
    },
  },
  importedCourseDetails: {
    path: '/admin/courses/imported/:courseId/details',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/imported/${courseId}/details`,
    name: 'Imported Course Details',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadImportedCourses,
      ],
    },
  },
  importedCourseStudents: {
    path: '/admin/courses/imported/:courseId/students',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/imported/${courseId}/students`,
    name: 'Imported Course students',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadImportedCourses,
      ],
    },
  },
  importedCourseInstructors: {
    path: '/admin/courses/imported/:courseId/instructors',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/imported/${courseId}/instructors`,
    name: 'Imported Course Instructors',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadImportedCourses,
      ],
    },
  },
  specializationOverview: {
    path: '/admin/courses/course-bundle',
    pathFn: ({ specializationId }: PathFuncParam) =>
      `/admin/courses/course-bundle/${specializationId}`,
    name: 'Course Bundle Overview',
    meta: {
      title: 'sidemenu.specialization',
    },
  },
  specializationDetails: {
    path: '/admin/courses/course-bundle/:specializationId/details',
    pathFn: ({ specializationId }: PathFuncParam) =>
      `/admin/courses/course-bundle/${specializationId}/details`,
    name: 'Course Bundle Details',
    meta: {
      title: 'sidemenu.specialization',
    },
  },
  specializationCreate: {
    path: '/admin/courses/course-bundle/create',
    name: 'Course Bundle create',
    meta: {
      title: 'sidemenu.specialization',
    },
  },
  SpecializationCourses: {
    path: '/admin/courses/course-bundle/:specializationId/courses',
    pathFn: ({ specializationId }: PathFuncParam) =>
      `/admin/courses/course-bundle/${specializationId}/courses`,
    name: 'Course Bundle courses',
    meta: {
      title: 'sidemenu.specialization',
    },
  },

  /** >>>>>>>>>>>>>>> Learning Categories Routes <<<<<<<<<<<<<<< **/
  LearningCategories: {
    path: '/admin/learning-categories',
    name: 'Learning Categories',
    meta: {
      title: 'sidemenu.LearningCategories',
      icon: 'mdi-book-multiple',
    },
  },
  SubLearningCategories: {
    path: '/admin/learning-categories/:learningCategoryId',
    pathFn: ({ learningCategoryId }: PathFuncParam) =>
      `/admin/learning-categories/${learningCategoryId}`,
    name: 'Sub Learning Categories',
    meta: {
      title: 'sidemenu.SubLearningCategories',
      icon: 'mdi-book-multiple',
    },
  },
  /** >>>>>>>>>>>>>>> Approvals Routes <<<<<<<<<<<<<<< **/
  approvals: {
    path: '/admin/approvals',
    name: 'Approvals View',
    meta: {
      icon: 'mdi-marker-check',
      title: 'sidemenu.approvals',
      memberPermissions: [EnumOrganizationMemberPermissions.ModifyCourses],
    },
  },

  /** >>>>>>>>>>>>>>> Grades Routes <<<<<<<<<<<<<<< **/
  grades: {
    path: '/admin/grades',
    name: 'Grades View',
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadCourses],
    },
  },
  gradesOverview: {
    path: '/admin/grades/:gradeId',
    name: 'Grade Schema view',
    pathFn: ({ gradeId }: PathFuncParam) => `admin/grades/${gradeId}`,
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
    },
  },
  gradesDetails: {
    path: '/admin/grades/:gradeId/details',
    pathFn: ({ gradeId }: PathFuncParam) => `admin/grades/${gradeId}/details`,
    name: 'Grade Schema Details',
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
    },
  },
  gradesLetters: {
    path: '/admin/grades/:gradeId/letters',
    pathFn: ({ gradeId }: PathFuncParam) => `admin/grades/${gradeId}/letters`,
    name: 'Grade Schema letters',
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
    },
  },
  createGradeSchema: {
    path: '/admin/grades/create',
    name: 'Grade Schema create',
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
    },
  },
  gradesCourses: {
    path: '/admin/grades/:gradeId/courses',
    name: 'Grade Schema Courses',
    pathFn: ({ gradeId }: PathFuncParam) => `admin/grades/${gradeId}/courses`,
    meta: {
      icon: 'mdi-view-module',
      title: 'sidemenu.grades',
    },
  },

  /** >>>>>>>>>>>>>>> Social Platform Routes <<<<<<<<<<<<<<< **/
  socialPlatform: {
    path: '/admin/social-platform',
    name: 'Socials Platforms View',
    meta: {
      icon: 'mdi-forum',
      title: 'sidemenu.socialPlatform',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialGroups],
    },
  },
  viewSocialPlatform: {
    path: '/admin/social-platform',
    name: 'Social Platform View',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialGroups],
    },
  },
  createSocialPlatform: {
    path: '/admin/social-platform/create',
    name: 'Create Group',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ModifySocialGroups],
    },
  },
  socialPlatformDetails: {
    path: '/admin/social-platform/:groupId/details',
    name: 'Group Details',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialGroups],
    },
  },
  viewSocialPlatformMembers: {
    path: '/admin/social-platform/:groupId/members',
    name: 'Group Members',
  },
  /** >>>>>>>>>>>>>>> Events Routes <<<<<<<<<<<<<<< **/
  events: {
    path: '/admin/events',
    name: 'Events View',
    meta: {
      icon: 'mdi-calendar-multiple',
      title: 'sidemenu.events',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  createEvent: {
    path: '/admin/events/create',
    name: 'Create Event',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ModifySocialEvents],
    },
  },
  eventView: {
    path: '/admin/events',
    name: 'Event View',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventDetails: {
    path: '/admin/events/:eventId/details',
    name: 'Event Details',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventHosts: {
    path: '/admin/events/:eventId/hosts',
    name: 'Event Hosts',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventGuests: {
    path: '/admin/events/:eventId/guests',
    name: 'Event Guests',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventEnrollees: {
    path: '/admin/events/:eventId/enrollees',
    name: 'Event Enrollees',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventAttendance: {
    path: '/admin/events/:eventId/attendance',
    name: 'Event Attendance',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },
  eventAttendanceItem: {
    path: '/admin/events/:eventId/attendance/:attendanceItemId',
    name: 'Event Attendance Item',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },

  eventQrCode: {
    path: '/admin/events/:eventId/qr-code',
    name: 'Event Qr Code',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.TakeSocialEventsAttendance,
      ],
    },
  },

  eventTicket: {
    path: '/admin/events/:eventId/ticket',
    name: 'Event Ticket',
    meta: {
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSocialEvents],
    },
  },

  /** >>>>>>>>>>>>>>> Transactions Routes <<<<<<<<<<<<<<< **/
  transactions: {
    path: '/admin/transactions',
    name: 'Transactions View',
    meta: {
      icon: 'mdi-credit-card',
      title: 'sidemenu.transactions',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSales],
      shouldHaveMarketPlaceEnabled: true,
    },
  },
  allTransactions: {
    path: '/admin/transactions/transactions',
    name: 'All Transactions',
    meta: {
      icon: 'mdi-credit-card',
      title: 'sidemenu.transactions',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSales],
    },
  },
  canceledTransactions: {
    path: '/admin/transactions/canceled-transactions',
    name: 'Canceled Transactions',
    meta: {
      icon: 'mdi-credit-card',
      title: 'sidemenu.transactions',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSales],
    },
  },
  /** >>>>>>>>>>>>>>> issued Certificates <<<<<<<<<<<<<<< **/
  issuedCertificates: {
    path: '/admin/issued-certificates',
    name: 'Issued Certificates',
    meta: {
      icon: 'mdi-certificate-outline',
      title: 'sidemenu.issuedCertificate',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberActivity],
      shouldHaveCertificateConfig: true,
    },
  },
  issuedCertificatesLayout: {
    path: '/admin/issued-certificates',
    name: 'Issued Certificates Layout',
    meta: {
      icon: 'mdi-certificate-outline',
      title: 'sidemenu.issuedCertificate',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberActivity],
    },
  },
  viewIssuedCertificates: {
    path: '/admin/issued-certificates/view-certificate',

    name: 'View Issued Certificate',
    meta: {
      icon: 'mdi-certificate-outline',
      title: 'sidemenu.issuedCertificate',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberActivity],
    },
  },

  /** >>>>>>>>>>>>>>> Permission Routes <<<<<<<<<<<<<<< **/
  permissions: {
    path: '/admin/permissions',
    name: 'Permissions View',
    meta: {
      icon: 'mdi-checkbox-multiple-marked',
      title: 'sidemenu.permissions',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadMemberRoles],
    },
  },

  /** >>>>>>>>>>>>>>> Organization Overview Routes <<<<<<<<<<<<<<< **/
  organizationOverview: {
    path: '/admin/organization-overview',
    name: 'Organization Overview View',
    meta: {
      icon: 'mdi-cog',
      title: 'sidemenu.organizationOverview',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
        EnumOrganizationMemberPermissions.ReadSupportTeams,
      ],
    },
  },
  organizationStyle: {
    path: '/admin/organization-overview/style',
    name: 'Organization Style ',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  /** >>>>>>>>>>>>>>>  Support Team Routes  <<<<<<<<<<<<<<< **/
  supportTeam: {
    path: '/admin/organization-overview/support-team',
    name: 'Support Team',
    meta: {
      title: 'sidemenu.supportTeam',
      memberPermissions: [EnumOrganizationMemberPermissions.ReadSupportTeams],
    },
  },
  organizationCoursesConfig: {
    path: '/admin/organization-overview/courses',
    name: 'Organization Courses ',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  organizationTerminology: {
    path: '/admin/organization-overview/terminology',
    name: 'Organization Terminology ',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  organizationShortTerm: {
    path: '/admin/organization-overview/terminology/short-term',
    name: 'Organization Terminology Short Term ',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  organizationLongTerm: {
    path: '/admin/organization-overview/terminology/long-term',
    name: 'Organization Terminology Long Term ',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  organizationMediaConfig: {
    path: '/admin/organization-overview/media',
    name: 'Organization Media',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  organizationCertificateConfig: {
    path: '/admin/organization-overview/certificate',
    name: 'Organization Certificate',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  /** >>>>>>>>>>>>>>>  Refunds Routes <<<<<<<<<<<<<<< **/
  refunds: {
    path: '/admin/refunds',
    name: 'Refunds',
    meta: {
      icon: 'mdi-credit-card-refund',
      title: 'sidemenu.refunds',
      memberPermissions: [EnumOrganizationMemberPermissions.ViewRefunds],
      shouldHaveMarketPlaceEnabled: true,
    },
  },

  /** >>>>>>>>>>>>>>>  NewsLetter  Routes  <<<<<<<<<<<<<<< **/
  newsLetters: {
    path: '/admin/newsLetter',
    name: 'NewsLetter',
    meta: {
      icon: 'mdi-email-newsletter',
      title: 'sidemenu.newsLetter',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ViewOrganizationNewsletter,
      ],
    },
  },
  /** >>>>>>>>>>>>>>>  Registration Request  Routes  <<<<<<<<<<<<<<< **/

  registrationRequest: {
    path: '/admin/users-registration-request',
    name: 'User Registration Request',
    meta: {
      icon: 'mdi-account-badge',
      title: 'sidemenu.registrationRequest',
      memberPermissions: [EnumOrganizationMemberPermissions.ModifyMembers],
      shouldHaveRegistrationApproval: true,
      shouldHaveSelfRegistration: true,
    },
  },

  /** >>>>>>>>>>>>>>> announcements Routes <<<<<<<<<<<<<<< **/
  announcements: {
    path: '/admin/announcement',
    name: 'Announcements View',
    meta: {
      icon: 'mdi-bullhorn',
      title: 'sidemenu.announcements',
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },
  createAnnouncement: {
    path: '/admin/announcement/create',
    name: 'Create Announcement',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
        EnumOrganizationMemberPermissions.ModifyOrganizationConfiguration,
      ],
    },
  },
  announcementDetails: {
    path: '/admin/announcement/:announcementId/details',
    name: 'Announcement Details',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.ReadOrganizationConfiguration,
      ],
    },
  },

  /** >>>>>>>>>>>>>>> articles Routes <<<<<<<<<<<<<<< **/
  articles: {
    path: '/admin/article',
    name: 'Article View',
    meta: {
      icon: 'mdi-newspaper',
      title: 'sidemenu.articles',
    },
  },
  createArticle: {
    path: '/admin/article/create',
    name: 'Create Article',
  },
  articleDetails: {
    path: '/admin/article/:articleId/details',
    name: 'Article Details',
  },

  /** >>>>>>>>>>>>>>>   Student View  Route  <<<<<<<<<<<<<<< **/

  AdminStudentView: {
    path: '/admin/courses/:courseId/student-view',
    pathFn: ({ courseId }: PathFuncParam) =>
      `/admin/courses/${courseId}/student-view`,
    name: 'Admin Student View',
    meta: {
      title: 'sidebar.studentView',
      icon: 'mdi-glasses',
      requiresAuth: true,
    },
  },
};
