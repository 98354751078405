export const coursesApiHandler = {
  managedCourses_SP: {
    instructors: {
      editOrganizationCourseInstructors: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/instructors`
      },
      getOrganizationCourseInstructors: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/instructors`,
        params: {} as {'skip'?: string | number, 'take'?: string | number}
      }
    },
    enrollees: {
      getOrganizationCourseEnrollees: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/enrollees`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'permissionSetId'?: string | number}
      },
      updateOrganizationCourseEnrollees: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/enrollees`
      }
    },
    creditUnits: {
      updateCourseCreditUnits: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/credit-units`
      },
      getCourseCreditUnits: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/credit-units`
      }
    },
    learningCategories: {
      updateCourseLearningCategories: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/learning-categories`
      },
      getCourseLearningCategories: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/learning-categories`
      }
    },
    passingCriteria: {
      getCoursePassingCriteria: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/passing-criteria`
      },
      updateCoursePassingCriteria: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/passing-criteria`
      }
    },
    customInstructions: {
      getCustomInstructions: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/custom-instructions`
      },
      updateCustomInstructions: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/custom-instructions`
      }
    },
    certificateTemplate: {
      getCourseCertificateTemplate: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/certificate-template`
      },
      updateCourseCertificateTemplate: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/certificate-template`
      }
    },
    sales: {
      addCourseSale: {
        method: "POST",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/sales`
      },
      getCourseSales: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/sales`
      },
      deleteCourseSale: {
        method: "DELETE",
        path: (payload: { courseId: string | number , saleId: string | number }) => `org-courses-management/${payload.courseId}/sales/${payload.saleId}`
      },
      updateCourseSale: {
        method: "PATCH",
        path: (payload: { courseId: string | number , saleId: string | number }) => `org-courses-management/${payload.courseId}/sales/${payload.saleId}`
      }
    },
    interestedStudents: {
      getInterestedStudents: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/interested-students`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'permissionSetId'?: string | number}
      }
    },
    partnerOrgs: {
      addPartnerOrgs: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/partner-organizations`
      },
      getPartnerOrgs: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/partner-organizations`
      },
      deletePartnerOrg: {
        method: "DELETE",
        path: (payload: { courseId: string | number , partnerId: string | number }) => `org-courses-management/${payload.courseId}/partner-organizations/${payload.partnerId}`
      }
    },
    refund: {
      getRefundableAmount: {
        method: "GET",
        path: (payload: { courseId: string | number , userId: string | number }) => `org-courses-management/${payload.courseId}/refund/amount/${payload.userId}`
      },
      requestRefund: {
        method: "POST",
        path: (payload: { courseId: string | number , userId: string | number }) => `org-courses-management/${payload.courseId}/refund/${payload.userId}`
      }
    },
    getOrganizationCourses: {
      method: "GET",
      path: () => `org-courses-management`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'orderBy[name]'?: string | number}
    },
    getOrganizationCourse: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}`
    },
    editOrganizationCourse: {
      method: "PATCH",
      path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}`
    },
    getOrganizationCourseStudentProgressChart: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/student-progress-charts`
    }
  },
  importedCourses_CO: {
    courseVisibility: {
      getImportedCourseVisibility: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/visibility`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'membersOnly'?: string | number, 'role'?: string | number, 'search'?: string | number, 'isVerified'?: string | number, 'requiresApproval'?: string | number}
      },
      updateImportedCourseVisibility: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/visibility`
      }
    },
    courseEnrollees: {
      getImportedCourseEnrollees: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/enrollees`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
      },
      updateImportedCourseEnrollees: {
        method: "PATCH",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/enrollees`
      }
    },
    passingCriteria: {
      getImportedCoursePassingCriteria: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/passing-criteria`
      },
      updateImportedCoursePassingCriteria: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/passing-criteria`
      }
    },
    courseInstructors: {
      getCourseInstructors: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/instructors`,
        params: {} as {'skip'?: string | number, 'take'?: string | number}
      }
    },
    certificateTemplate: {
      getImportedCourseCertificateTemplate: {
        method: "GET",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/certificate-template`
      },
      updateImportedCourseCertificateTemplate: {
        method: "PUT",
        path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/certificate-template`
      }
    },
    getAllImportedCourses: {
      method: "GET",
      path: () => `org-imported-courses-management`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'organizationId'?: string | number, 'search'?: string | number, 'orderBy[name]'?: string | number}
    },
    getImportedCoursesOrganizations: {
      method: "GET",
      path: () => `org-imported-courses-management/organizations`
    },
    getSingleImportedCourse: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}`
    },
    updateSingleImportedCourse: {
      method: "PATCH",
      path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}`
    },
    editIssuedCertificates: {
      method: "PATCH",
      path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/certificates`
    },
    getImportedCourseCertificate: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `org-imported-courses-management/${payload.courseId}/certificates`
    }
  },
  addBulkUsersToCourse: {
    method: "POST",
    path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/enrollees/bulk`
  }
};