import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

import { TestingDomains } from '@/helpers/constants/testing-domian';
import { LocaleStorageEnum } from '@/helpers/enums/locale-storage.enum.ts';
import type {
  CertificateTemplateData,
  IssuedCertificates,
  IssuedCertificatesCsv,
  Settings,
} from '@/helpers/interfaces/admin/certificate.interface';
import type { CoursePassingCriteriaObject } from '@/helpers/interfaces/admin/course.interface';
import type { MultiParams } from '@/helpers/interfaces/admin/multiParams.interface';
import type { FontFamilies } from '@/helpers/interfaces/super-admin/font-families.interface';
import type { CreditHoursData } from '@/helpers/interfaces/super-admin/organizations.interface';
import type { TerminologyTerm } from '@/helpers/interfaces/super-admin/terminology.interface';
import { axiosInstance } from '@/plugins';
import { useToasterStore } from '@/stores/shared/toaster.pinia.ts';
import { ApiHandler } from '@/submodules/adminApi/apiHandler';
import { Organization } from '@/submodules/generated_types/types/organization';
import { ApiHandler as instructorApiHandler } from '@/submodules/instructorApi/apiHandler';
import { ApiHandler as supeAdminAPI } from '@/submodules/super-admin/apiHandler';

import type { Paginated } from '@/components/base/paginated/paginated-type.intefcaces.ts';

import { useConfigurationStore } from './configuration.pinia';

export const useOrganizationStore = defineStore('organizationStore', () => {
  const toasterStore = useToasterStore();
  const issuedCertificateCsv: Ref<IssuedCertificatesCsv[] | null> = ref(null);
  const issuedCertificate: Ref<Paginated<IssuedCertificates> | null> =
    ref(null);

  const organization: Ref<Organization | undefined> = ref(undefined);
  const organizationTerms: Ref<Paginated<TerminologyTerm> | null> = ref(null);
  const singleOrganizationTerm: Ref<TerminologyTerm | null> = ref(null);
  const fontFamilies: Ref<Paginated<FontFamilies> | null> = ref(null);
  const organizationCreditHours: Ref<Paginated<CreditHoursData> | null> =
    ref(null);
  const organizationTerminology: Ref<Record<string, TerminologyTerm[]>> = ref(
    {}
  );
  const organizationPassingCriteria: Ref<CoursePassingCriteriaObject | null> =
    ref(null);

  const certificates: Ref<CertificateTemplateData | null> = ref(null);

  const callOrganization = async () => {
    try {
      const { path, method } = ApiHandler.getOrganization;
      const { data } = await axiosInstance<Organization>(path(), {
        method,
      });
      organization.value = data;

      useConfigurationStore().setOrganizationConfigKeys();
      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const updateOrganization = async (payload: {
    name?: string;
    logoId?: number | null;
    iconId?: number | null;
    primary?: string;
    secondary?: string;
    fontFamilies?: { fontFamilyId: number; language: string }[];
    passingCriteria?: boolean;
  }) => {
    try {
      const { path, method } = ApiHandler.updateOrganization;

      const { data } = await axiosInstance(path(), {
        method,
        data: {
          name: payload.name,
          iconId: payload.iconId,
          logoId: payload.logoId,
          colors: {
            primary: payload.primary,
            secondary: payload.secondary,
          },
          fontFamilies: payload.fontFamilies,
          config: {
            'passing-criteria-configuration-enabled-for-instructors':
              payload.passingCriteria,
          },
        },
      });
      organization.value = data;
      toasterStore.updated();

      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const fetchFontFamilies = async (
    params: typeof supeAdminAPI.configurations.fontFamilies.getFontFamilies.params
  ) => {
    try {
      const { path, method } =
        supeAdminAPI.configurations.fontFamilies.getFontFamilies;

      const { data } = await axiosInstance(path(), {
        method,
        params,
      });
      fontFamilies.value = data;

      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const getOrganizationCreditHours = async (
    params: typeof ApiHandler.creditUnits.getAllCreditUnits.params
  ) => {
    try {
      const { path, method } = ApiHandler.creditUnits.getAllCreditUnits;

      const { data } = await axiosInstance<Paginated<CreditHoursData>>(path(), {
        method,
        params,
      });
      organizationCreditHours.value = data;
      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const editOrganizationCreditHourName = async (
    creditUnitId: number,
    name: string,
    unitAbbreviation: string | null
  ) => {
    try {
      const { path, method } = ApiHandler.creditUnits.updateCreditUnit;

      const { data } = await axiosInstance<Paginated<CreditHoursData>>(
        path({ creditUnitId: creditUnitId }),
        {
          method,
          data: {
            name: name,
            abbreviation: unitAbbreviation,
          },
        }
      );
      toasterStore.updated();

      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const createOrganizationCreditHour = async (
    name: string,
    unitAbbreviation: string | null
  ) => {
    try {
      const { path, method } = ApiHandler.creditUnits.createCreditUnit;

      const { data } = await axiosInstance<Paginated<CreditHoursData>>(path(), {
        method,
        data: {
          name: name,
          abbreviation: unitAbbreviation,
        },
      });
      toasterStore.created();
      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const deleteOrganizationCreditHour = async (creditUnitId: number) => {
    try {
      const { path, method } = ApiHandler.creditUnits.deleteCreditUnit;

      const { data } = await axiosInstance<Paginated<CreditHoursData>>(
        path({
          creditUnitId: creditUnitId,
        }),
        {
          method,
          data: {
            name: name,
          },
        }
      );
      toasterStore.deleted();

      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const getOrganizationTerms = async (
    params: typeof ApiHandler.terminology.getOrganizationsTerminologyDashboard.params
  ) => {
    try {
      const { path, method } =
        ApiHandler.terminology.getOrganizationsTerminologyDashboard;

      const { data } = await axiosInstance<Paginated<TerminologyTerm>>(path(), {
        method,
        params,
      });
      organizationTerms.value = data;
      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const createOrganizationTerms = async (
    termKeyId: number,
    language: string,
    value: {
      singular: string;
      plural?: string;
    },
    mediumId?: number
  ) => {
    try {
      const { path, method } =
        ApiHandler.terminology.createOrganizationsTerminology;

      const { data } = await axiosInstance<Paginated<TerminologyTerm>>(path(), {
        method,
        data: {
          termKeyId: termKeyId,
          language: language,
          value: value,
          mediumId: mediumId,
        },
      });
      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const editOrganizationTerms = async (
    terminologyId: number,
    language: string,
    value: { singular: string; plural?: string },
    mediumId?: number | null
  ) => {
    try {
      const { path, method } =
        ApiHandler.terminology.updateOrganizationsTerminology;

      const { data } = await axiosInstance<Paginated<TerminologyTerm>>(
        path({ terminologyId: terminologyId }),
        {
          method,
          data: {
            language: language,
            value: value,
            mediumId: mediumId,
          },
        }
      );
      toasterStore.updated();

      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const getOrganizationTermByTermId = async (termId: number) => {
    try {
      const { path, method } = ApiHandler.terminology.getOrganizationsTerm;

      const { data } = await axiosInstance<TerminologyTerm>(
        path({ termId: termId }),
        {
          method,
        }
      );
      singleOrganizationTerm.value = data;
      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };

  const getOrganizationTerminology = async () => {
    try {
      const { path, method } =
        instructorApiHandler.organizations.getOrganizationsTerminology;
      const { data } = await axiosInstance<Record<string, TerminologyTerm[]>>(
        path(),
        {
          method,
        }
      );
      organizationTerminology.value = data;
      useConfigurationStore().setOrganizationTerms();
      return data;
    } catch (error) {
      return error;
    }
  };
  const getCertificates = async () => {
    try {
      const { path, method } =
        ApiHandler.certificateTamplate.getCertificateTemplate;

      const { data } = await axiosInstance<CertificateTemplateData>(path(), {
        method,
      });
      certificates.value = data;
      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const updateCertificateTemplate = async (
    settings: Settings,
    templateEta: string
  ) => {
    try {
      const { path, method } =
        ApiHandler.certificateTamplate.updateCertificateTemplate;

      const { data } = await axiosInstance<CertificateTemplateData>(path(), {
        method,
        data: {
          templateEta: templateEta,
          settings: settings,
        },
      });
      toasterStore.updated();
      certificates.value = data;
      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const getOrganizationPassingCriteria = async () => {
    try {
      const { path, method } =
        ApiHandler.passingCriteria.getOrganizationPassingCriteria;

      const { data } = await axiosInstance<CoursePassingCriteriaObject | null>(
        path(),
        {
          method,
        }
      );
      organizationPassingCriteria.value = data;
      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };
  const updateOrganizationPassingCriteria = async (payload: {
    passingGradePercentage: number;
    mandatoryAttendancePercentage: number;
  }) => {
    try {
      const { path, method } =
        ApiHandler.passingCriteria.updateOrganizationPassingCriteria;

      const { data } = await axiosInstance(
        path(),

        {
          method,
          data: {
            passingGradePercentage: payload.passingGradePercentage,
            mandatoryAttendancePercentage:
              payload.mandatoryAttendancePercentage,
          },
        }
      );
      toasterStore.updated();
      return data;
    } catch (e) {
      toasterStore.error;
      return e as AxiosError;
    }
  };

  /*
  get url to check which organization prefix and id
   */
  const getOrganizationUsingPrefix = async (prefix: string) => {
    try {
      const { path, method } =
        instructorApiHandler.organizations.getIdFromPrefix;
      const { data } = await axiosInstance<Organization>(
        path({ prefix: prefix }),
        {
          method,
        }
      );
      localStorage.setItem(
        LocaleStorageEnum.ORGANIZATION_ID,
        TestingDomains.includes(import.meta.env.VITE_PROJECT_NAME)
          ? import.meta.env.VITE_CLIENT_ORGANIZATION_ID
          : data.id.toString()
      );
      return data;
    } catch (error) {
      useToasterStore().error();
    }
  };
  const getOrganizationIssuedCertificate = async (params: {
    skip: number;
    take: number;
    search?: string;
    orderBy?: MultiParams | null;
  }) => {
    try {
      const { path, method } =
        ApiHandler.issuedCertificates.getOrganizationIssuedCertificates;
      const { data } = await axiosInstance<Paginated<IssuedCertificates>>(
        path(),
        {
          method,
          params,
        }
      );
      issuedCertificate.value = data;
      return data;
    } catch (error) {
      useToasterStore().error();
      return error as AxiosError;
    }
  };
  const getOrganizationIssuedCertificateCsv = async (params: {
    skip: number;
    take: number;
    search?: string;
    orderBy?: MultiParams | null;
  }) => {
    try {
      const { path, method } =
        ApiHandler.issuedCertificates.getOrganizationIssuedCertificates;
      const { data } = await axiosInstance<Paginated<IssuedCertificates>>(
        path(),
        {
          method,
          params,
        }
      );
      issuedCertificateCsv.value =
        data.data.map((certificates: IssuedCertificates) => {
          return {
            studentName:
              certificates.user?.firstName + ' ' + certificates.user.lastName,
            courseName: certificates.course?.name,
            organizationName: certificates.course?.organization?.name ?? '',
            id: certificates.id,
            date: certificates.createdAt,
            url: certificates.media[0]?.medium?.url || '-',
          };
        }) || [];
    } catch (error) {
      useToasterStore().error();
      return error as AxiosError;
    }
  };
  return {
    updateOrganization,
    getOrganizationIssuedCertificate,
    editOrganizationTerms,
    updateCertificateTemplate,
    getCertificates,
    issuedCertificateCsv,
    issuedCertificate,
    updateOrganizationPassingCriteria,
    getOrganizationIssuedCertificateCsv,
    certificates,
    getOrganizationPassingCriteria,
    organizationPassingCriteria,
    createOrganizationTerms,
    getOrganizationTermByTermId,
    createOrganizationCreditHour,
    callOrganization,
    editOrganizationCreditHourName,
    deleteOrganizationCreditHour,
    getOrganizationTerms,
    singleOrganizationTerm,
    organizationCreditHours,
    getOrganizationCreditHours,
    fetchFontFamilies,
    organization,
    fontFamilies,
    getOrganizationTerminology,
    organizationTerminology,
    getOrganizationUsingPrefix,
  };
});
