export const learningCategoriesApiHandler = {
  createLearningCategory: {
    method: "POST",
    path: () => `learning-categories`
  },
  getLearningCategoriesForAdmin: {
    method: "GET",
    path: () => `learning-categories/admin`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'parentId'?: string | number}
  },
  getLearningCategoryById: {
    method: "GET",
    path: (payload: { id: string | number }) => `learning-categories/admin/${payload.id}`
  },
  getParentLearningCategoriesForAdmin: {
    method: "GET",
    path: () => `learning-categories/admin/parent`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  updateLearningCategory: {
    method: "PATCH",
    path: (payload: { categoryId: string | number }) => `learning-categories/${payload.categoryId}`
  },
  deleteLearningCategory: {
    method: "DELETE",
    path: (payload: { categoryId: string | number }) => `learning-categories/${payload.categoryId}`
  }
};